
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
import Search from "@/components/Form/Search.vue";
import SelectItem from "@/components/Form/SelectItem.vue";
import Table from "@/components/Table.vue";
import Accordion from "@/components/Accordion.vue";
import { Constants } from "@/constants/constants";
import {
  OverviewStudent,
  StudentParameters,
  PagedList,
  SortOrder,
} from "@/interfaces/api";
import { INameAndId } from "@/interfaces/INameAndId";
import { IPagingData } from "@/interfaces/IPagingData";
import { IUserPagingData } from "@/interfaces/IUserPagingData";
import StudentService from "@/services/student-service";
import LocalStorageService from "@/services/localstorage-service";
import CoordinatorService from "@/services/coordinator-service";

@Options({
  components: {
    Search,
    Table,
    Accordion,
    SelectItem,
  },
  data: () => ({
    today: dayjs(),
    /**
     * The current sort column
     */
    sortColumn: "personalId",
    /**
     * If sort order is ascending
     */
    ascending: false,
    /**
     * The current search value
     */
    search: "",
    /**
     * All rows in the table
     */
    rows: [],
    /**
     * The url to detailed view
     */
    navigateToDetailsUrl: "/administrator/studentdetails/",

    /**
     * Holds the paging data sent back with the list of students from the backend
     */
    pagingData: {},

    /**
     * Used to delay search phrase changes calling backend
     */
    timer: null,
    /**
     * If the data is still loading - passed to the table component to show spinner
     */
    isLoading: true,
    /*
     * Holds a list of coordinator names and ids and is populated by a call to the coordinator service when the page loads
     */
    coordinators: new Array<INameAndId>(),
    /**
     * The selected coordinator to filter on
     */
    selectedCoordinator: { id: 0, name: "" },
  }),
  computed: {
    /**
     * The page heading to be displayed above the table
     */
    pageHeading() {
      return this.$t("shared.routes.student-overview");
    },
  },

  methods: {
    /**
     * Iterates a filter and saves selected options to localstorage
     */
    iterateFilter(
      filter: { [x: string]: { [x: string]: boolean } },
      array: string[],
      filterType: string
    ): void {
      for (let group_key in filter) {
        for (let option_key in filter[group_key]) {
          const storageKey = `selected_${filterType}_${option_key}`;

          if (filter[group_key][option_key]) {
            array.push(option_key);
            LocalStorageService.setItem(storageKey, "true");
          } else {
            LocalStorageService.setItem(storageKey, "false");
          }
        }
      }
    },

    /**
     * Fetches students that meet the required search, filter and paging data
     */
    getStudentsBySearch(): void {
      const parameters = this.getStudentParameters();
      this.isLoading = true;

      StudentService.getFiltered(parameters)
        .then((data: PagedList<OverviewStudent>) => {
          this.populatePagingData(data);
          this.saveFilteredStudentIds(data.sortedIds);
          this.rows = data.items.map((student: OverviewStudent) => {
            return {
              id: student.id,
              personalId: student.personalId,
              firstName: student.firstName,
              lastName: student.lastName,
              email: student.email,
              coordinatorName: student.coordinatorName,
              grantedSupportDate: dayjs(student.grantedSupportDate),
            };
          });
          this.isLoading = false;
        })
        .catch((e: Error) => {
          console.error(e);
        });
    },

    /**
     * Populates and returns parameters for the getFiltered method in the student service
     */
    getStudentParameters(): StudentParameters {
      return {
        search: this.search.toLowerCase(),
        sortBy: this.sortColumn,
        sortOrder: this.ascending ? SortOrder.Asc : SortOrder.Desc,
        pageNumber: this.pagingData.currentPage || 1,
        pageSize: this.pagingData.pageSize || 10,
        coordinatorId: this.selectedCoordinator.id,
      };
    },

    /**
     * Saves the ids of all filtered students to localstorage
     *
     * @param {StudentParameters} studentParameters - The filter/paging parameters to be added to the service call
     */
    saveFilteredStudentIds(sortedIds: number[]): void {
      LocalStorageService.setItem(Constants.FilteredStudentIds, sortedIds);
    },

    /**
     * Populates paging data received from the backend to be passed to the table component
     */
    populatePagingData(data: PagedList<OverviewStudent>): void {
      const pagingData: IPagingData = {
        currentPage: data.currentPage,
        totalPages: data.totalPages,
        pageSize: data.pageSize,
        totalCount: data.totalCount,
        hasPrevious: data.hasPrevious,
        hasNext: data.hasNext,
      };

      this.pagingData = pagingData;
    },
    /**
     * Generates user paging data to be saved to local storage
     */
    generateUserPagingData(): IUserPagingData {
      const userPagingData: IUserPagingData = {
        sortColumn: this.sortColumn,
        sortOrder: this.ascending ? SortOrder.Asc : SortOrder.Desc,
        pageSize: this.pagingData.pageSize,
        coordinator: this.selectedCoordinator,
      };

      return userPagingData;
    },
    /**
     * Triggered when the user changes the page number of the table
     */
    onPageNumberChange(pageNumber: number): void {
      this.pagingData.currentPage = pageNumber;
      this.getStudentsBySearch();
    },
    /**
     * Triggered when the user changes the sort column/order of the table
     */
    onSortChange(columnName: string, ascending: boolean): void {
      this.sortColumn = columnName;
      this.ascending = ascending;
      LocalStorageService.setItem(
        Constants.StudentTableUserPagingData,
        this.generateUserPagingData()
      );
      this.getStudentsBySearch();
    },
    /**
     * Triggered when the user changes the page size of the table
     */
    onPageSizeChange(pageSize: number): void {
      this.pagingData.pageSize = pageSize;
      LocalStorageService.setItem(
        Constants.StudentTableUserPagingData,
        this.generateUserPagingData()
      );
      this.getStudentsBySearch();
    },
    /**
     * Triggered when the user enters search text
     */
    onSearchChange(searchPhrase: string): void {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search = searchPhrase;
        this.getStudentsBySearch();
      }, Constants.SearchSubmissionDelayMilliseconds);
    },
    /**
     * Triggered when the user changes the selected coordinator option
     */
    onCoordinatorChange(coordinator: INameAndId): void {
      this.selectedCoordinator = coordinator;
      this.pagingData.coordinator = coordinator;
      LocalStorageService.setItem(
        Constants.StudentTableUserPagingData,
        this.generateUserPagingData()
      );
      this.getStudentsBySearch();
    },
    /**
     * Gets the user's paging data that has been saved to local storage
     */
    getUsersPagingData(): void {
      const userPagingData = LocalStorageService.getItem<IUserPagingData>(
        Constants.StudentTableUserPagingData
      );

      this.pagingData.pageSize = 10;

      if (userPagingData) {
        this.sortColumn = userPagingData.sortColumn;
        this.ascending = userPagingData.sortOrder === SortOrder.Asc;
        this.pagingData.pageSize = userPagingData.pageSize;
        if (userPagingData.coordinator) {
          this.selectedCoordinator = userPagingData.coordinator;
        }
      }
    },
    /**
     * Fetches the coordinators for the function with the supplied id
     */
    getCoordinators() {
      CoordinatorService.getCachedCoordinatorsByFunctionId(
        Constants.NoteTakingFunctionId
      ).then((coordinators) => {
        this.coordinators = coordinators;
      });
    },
  },
  async mounted() {
    this.getUsersPagingData();
    this.getStudentsBySearch();
    this.getCoordinators();
  },
})
export default class StudentList extends Vue {}
